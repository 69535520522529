// extracted by mini-css-extract-plugin
export var content = "work-module--content--a5ebf";
export var count = "work-module--count--d7a5d";
export var cta = "work-module--cta--dfc13";
export var des = "work-module--des--6eee8";
export var end = "work-module--end--baec3";
export var focused = "work-module--focused--3e1fe";
export var ghstars = "work-module--ghstars--235ab";
export var header = "work-module--header--ee70c";
export var highlight = "work-module--highlight--f160b";
export var icon = "work-module--icon--d0dd9";
export var info = "work-module--info--c0840";
export var item = "work-module--item--275b6";
export var line = "work-module--line--025c2";
export var media = "work-module--media--f062c";
export var mediaList = "work-module--mediaList--2a320";
export var name = "work-module--name--47148";
export var portfolio = "work-module--portfolio--04c6f";
export var stars = "work-module--stars--bcf06";
export var start = "work-module--start--77245";
export var timeline = "work-module--timeline--15ca1";
export var title = "work-module--title--66590";
export var twitter = "work-module--twitter--fe234";
export var youtube = "work-module--youtube--99b34";