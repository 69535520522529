import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WorkPage from "../components/work"

const IndexPage = () => {
  return (
    <Layout wide>
      <SEO title="Udara's work" />
      <div className="work">
        <WorkPage />
      </div>
    </Layout>
  )
}

export default IndexPage
